.text-editor {
    width: 100%;
    margin: 0 auto;
  }
  .ql-editor {
    min-height: 100px;
  }
  

#haztartasi-vizlagyito .iframe-content{
    margin-left: 0;
    width: 100%;
}

.sor-torles .dx-icon-trash.icon{
    font: 26px/1 DXIcons;
    color: #e67e22;
    cursor: pointer;
}

#igenybejelento-form .card{
    margin-top: 15px;
    font-size: 8px;
}

#igenybejelento-form .card-header{
    background-color: rgba(33, 33, 33, 0.7);
}

.igenybejelento-box-bal,
.igenybejelento-box-jobb {
    margin: 0 4.1655555%;
}

#igenybejelento-form {
    padding: 35px 0;
}

#igenybejelento-form textarea {
    height: 365px;
}

#igenybejelento-form .button-container button {
    background-color: rgba(33, 33, 33, 0.7);
    margin: 35px 4.1655555% 0 0;
    color: white;
}

#igenybejelento-form .button-container {
    text-align: right;
}

#igenybejelento-form label.form-label {
    font-size: 18px;
    color: #f14805;
    margin-top: 35px;
}

#dokumentumok .icon {
    font-size: 28px;
    color: #f14805;
}

#dokumentumok a {
    text-decoration: none;
}

#dokumentumok-grid {
    max-width: initial !important;
}

.logo_container {
    max-width: 500px;
    margin: 60px auto;
}

.logo_container h1 {
    text-align: center;
    color: white;
}

.logo_container form {
    background-color: white;
    padding: 30px;
}

.logo_container form .input-group {
    margin-bottom: 15px;
}

.logo_container form label {
    display: block;
    margin-bottom: 10px;
}

.logo_container form input {
    padding: 12px 20px;
    width: 100%;
    border: 1px solid #ccc;
}

.logo_container .submit-btn {
    width: 100%;
    border: none;
    background: rgba(33, 33, 33, 0.7);
    font-size: 18px;
    color: white;
    border-radius: 3px;
    padding: 20px;
    text-align: center;
}

#partnereink .row,
#legkondicionalok .row {
    margin: 35px 0;
    text-align: center;
    color: rgba(33, 33, 33, 0.7);
    font-size: 46px;
}

#legkondicionalok .row {
    font-size: 32px;
}

#partnereink .col-sm,
#legkondicionalok .col-sm {
    padding: 25px;
}

#partnereink .col-sm img {
    max-width: 50%;
}

#legkondicionalok .col-sm img {
    max-width: 33%;
}

.product-image img {
    max-height: 365px;
    max-width: 365px;
}

.products-box {
    padding: 25px;
}

#vegyszerek .container {
    margin-bottom: 65px;
    text-align: center;
}

.page-fragment-box div img {
    width: 80%;
}

.btn-warning {
    background-color: #e67e22;
}

div#login-form {
    width: 70%;
    margin: auto;
    padding: 45px 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    text-align: center;
}

.iframe-content .products-box {
    text-align: center;
    text-align: center;
    width: calc(33% - 15px);
    float: left;
    margin-bottom: 35px;
    margin-left: 10px;
    margin-top: 35px;
}

.iframe-content .products-box img {
    text-align: center;
    max-width: 80%;
}

.iframe-inner-content {
    padding: 25px;
}

.iframe-content h3 {
    margin: 25px 0;
}

.iframe-content iframe {
    margin: 65px 0;
}
/* 
.iframe-content {
    width: 80%;
    margin: auto;
} */

.page-fragment-box .card-body {
    border-right: 3px solid #e67e22;
    box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, -12px 0 15px -4px #e67e22 inset;

}

#logo {
    max-width: 240px;
    margin-top: -58px;
    cursor: pointer;
}

#logo-842 {
    display: none;
}

@media (max-width: 842px) {
    #logo-842 {
        display: block;
        max-width: 65px;
        cursor: pointer;
        top: 10px;
        left: 5px;
        position: absolute;
    }

    #menu-place {
        width: 25px;
        display: inline-block;
    }
}

#rolunk,
#ajanlataink,
#referenciak,
#partnereink,
#kapcsolat,
#legkondicionalok,

#hoszivattyu,
#legtechnika,
#integralt,
#felulet-futeshutes,
#futeshutes,
#fancoil,
#air-leaf,
#air-leaf-sls,
#filomuro,
#vizlagyito,
#haztartasi-vizlagyito,
#igenybejelento,
#dokumentumok,
#vegyszerek,
#dokumentumok-list,
#login,
#klima {
    padding: 25px;
}

@keyframes slideInFromLeft {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}

#rolunk header,
#ajanlataink header,
#integralt header,
#referenciak header,
#partnereink header,
#kapcsolat header,
#legkondicionalok header,

#hoszivattyu header,
#legtechnika header,
#felulet-futeshutes header,
#futeshutes header,
#fancoil header,
#vizlagyito header,
#haztartasi-vizlagyito header,
#igenybejelento header,
#dokumentumok header,
#vegyszerek header,
#dokumentumok-list header,
#air-leaf header,
#air-leaf-sls header,
#filomuro header,
#login header,
#klima header,

.iframe-content header {
    color: #e67e22;
    animation: 1.5s ease-out 0s 1 slideInFromLeft;
    background: rgba(33, 33, 33, 0.7);
    padding: 30px;
    text-transform: uppercase;
    letter-spacing: 4px;
}

#rolunk .text-container,
#ajanlataink .text-container,
#integralt .text-container,
#felulet-futeshutes .text-container,
#referenciak .text-container,
#partnereink .text-container,
#kapcsolat .text-container,
#legkondicionalok .text-container,

#hoszivattyu .text-container,
#legtechnika .text-container,
#futeshutes .text-container,
#fancoil .text-container,
#vizlagyito .text-container,
#haztartasi-vizlagyito .text-container,
#igenybejelento .text-container,
#dokumentumok .text-container,
#vegyszerek .text-container,
#dokumentumok-list .text-container,
#air-leaf .text-container,
#air-leaf-sls .text-container,
#filomuro .text-container,
#login .text-container,
#klima .text-container {
    padding: 4rem !important;
}

#rolunk,
#ajanlataink,
#integralt,
#legkondicionalok,

#felulet-futeshutes,
#referenciak,
#partnereink,
#kapcsolat,

#hoszivattyu,
#legtechnika,
#futeshutes,
#fancoil,
#vizlagyito,
#haztartasi-vizlagyito,
#igenybejelento,
#dokumentumok,
#vegyszerek,
#dokumentumok-list,
#air-leaf,
#air-leaf-sls,
#filomuro,
#login,
#klima,
#levego-viz-hoszivattyu

 {
    background-color: rgba(0, 0, 0, 0.05);
    margin: 0 0 0px 0;
    margin-top: 50px;
    width: calc(100% - 240px);
    margin-left: 240px;
    min-height: 992px;
    overflow-x: hidden;
}

#pageFragment {
    min-height: 992px;
    padding: 0;
}

#pageFragment .row {
    --bs-gutter-x: 0;
    width: 100%;
}

.page-fragment-1,
.page-fragment-2,
.page-fragment-3,
.page-fragment-4,
.page-fragment-5,
.page-fragment-6 {
    margin: 4%;
    float: left;
}

#pageFragment .card-body {
    min-height: 225px;
}


@media (max-width: 1194px) {

    #rolunk,
    #ajanlataink,
    #integralt,
    #felulet-futeshutes,
    #referenciak,
    #partnereink,
    #kapcsolat,
    #legkondicionalok,

    #hoszivattyu,
    #legtechnika,
    #futeshutes,
    #fancoil,
    #vizlagyito,
    #haztartasi-vizlagyito,
    #igenybejelento,
    #dokumentumok,
    #vegyszerek,
    #dokumentumok-list,
    #air-leaf,
    #air-leaf-sls,
    #filomuro,
    #login,
    #klima,
    #levego-viz-hoszivattyu {
        margin-top: 84px;
    }

    #legkondicionalok .iframe-content .products-box {
        float: none;
        width: 100%;
        margin-left: initial;
    }

}

@media (max-width: 1020px) {

    .snip1226 li{
        font-size: 14px;
    }

    #sidebar-menu.collapse:not(.show),#sidebar-menu2.collapse:not(.show) {
        display: none !important;
    }

    #rolunk,
    #ajanlataink,
    #integralt,
    #felulet-futeshutes,
    #referenciak,
    #partnereink,
    #kapcsolat,
    #legkondicionalok,

    #hoszivattyu,
    #legtechnika,
    #futeshutes,
    #fancoil,
    #vizlagyito,
    #haztartasi-vizlagyito,
    #igenybejelento,
    #dokumentumok,
    #vegyszerek,
    #dokumentumok-list,
    #air-leaf,
    #air-leaf-sls,
    #filomuro,
    #login,
    #klima,
    #levego-viz-hoszivattyu,
    .iframe-content {
        margin-left: initial;
        width: 100%;
    }

    .iframe-content .products-box {
        float: none;
        width: 100%;
        margin-left: initial;
    }


    .page-fragment-1,
    .page-fragment-2,
    .page-fragment-3,
    .page-fragment-4,
    .page-fragment-5,
    .page-fragment-6 {
        width: 92%;
    }

}

@media (max-width: 940px) {

    #main-navbar .navbar-brand {
        position: absolute;
    }
}


@media (max-width: 678px) {

    #rolunk,
    #ajanlataink,
    #integralt,

    #felulet-futeshutes,
    #referenciak,
    #partnereink,
    #kapcsolat,
    #legkondicionalok,

    #hoszivattyu,
    #levego-viz-hoszivattyu,
    #legtechnika,
    #futeshutes,
    #fancoil,
    #air-leaf,
    #filomuro,
    #vizlagyito,
    #haztartasi-vizlagyito,
    #igenybejelento,
    #dokumentumok,
    #vegyszerek,
    #dokumentumok-list,
    #air-leaf,
    #air-leaf-sls,
    #filomuro,
    #login,
    #klima {
        margin-top: 150px;
    }

}


@media (max-width: 518px) {

    #rolunk,
    #ajanlataink,
    #integralt,

    #felulet-futeshutes,
    #referenciak,
    #partnereink,
    #kapcsolat,
    #legkondicionalok,

    #hoszivattyu,
    #levego-viz-hoszivattyu,
    #legtechnika,
    #futeshutes,
    #fancoil,
    #vizlagyito,
    #haztartasi-vizlagyito,
    #igenybejelento,
    #dokumentumok,
    #vegyszerek,
    #dokumentumok-list,
    #air-leaf,
    #air-leaf-sls,
    #filomuro,
    #login,
    #klima {
        margin-top: 182px;
    }

}