/* own css */
.main-container{
    padding-bottom: 175px;
}

.fooldal-box .card-body{
    border-right: 3px solid #e67e22;
}

.page-fragment-box{cursor: pointer;}

.fooldal-box img, .page-fragment img{
    display: block;
    margin: auto;
    margin-bottom: 17px;
    max-height: 240px;
    max-width: 80%;
}

.fooldal-box i{
    margin-left: 5px;
    font-weight: 900;
    font-size: 16px;
}

#gridGallery {
      margin: 0 0 0px 0;
      margin-top: 58px;
      width: calc(100% - 265px);
      margin-left: 253px;
  }


  @media (max-width: 992px) {
      #gridGallery {
          width: calc(100% - 12px);
          margin-left: initial;
      }
  }

  .row > * {
    padding-right: 0;
    padding-left: 0;
  }

  #gridGallery .card,.page-fragment-box .card{
    border: none;
    border-radius: initial;
  }

  .fooldal-box .card{
    color: rgba(255, 255, 255, 0.5);   
    padding: 35px;
  }

  .fooldal-box .card a,.fooldal-box .card a:active,.fooldal-box .card a:focus,.fooldal-box .card a:hover{
    color: #e67e22;
    background-color: rgba(255, 255, 255, 0.5);
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-color: rgba(255, 255, 255, 1);
    border-radius: initial;
  }
  .fooldal-box .card a:hover{color:white}
  .fooldal-box .card-header{color:rgb(108, 117, 125)}

  #boxOne .card,#boxTwo .card,#boxEight .card,#boxFour .card{height: 500px;}
  #boxThree .card,#boxFive .card,#boxSix .card{height: 223px;}

  /* userAgent css */
  h2,
  .ms-WPBody h2 {
      color: #000;
  }

  .box h3 {
      color: #fff;
      font-family: 'Source Sans Pro';
      font-weight: 700;
  }

  section {
      margin: 25px 0;
  }

  #gridGallery .row,  #gridGallery .card-body,.page-fragment-box .card-body{
      background-color: rgb(33 33 33 / 20%) !important;
  }

  .box {
      background-position: center center;
      background-size: cover;
      border: 1px solid #fff;
      transition: opacity .25s;
      overflow: hidden;
  }

  .hiddenText {
      display: none !important;
  }

  .box:hover {
      cursor: pointer;
      z-index: 1;
  }

  #boxOne,
  #boxTwo,
  #boxFour,
  #boxSeven,
  #boxEight {
      height: 500px;
  }

  #boxThree,
  #boxFive,
  #boxSix {
      height: 225px;
  }

  .full {
      float: none;
      height: 900px !important;
      width: 1170px;
      position: absolute;
      z-index: 2;
  }

  #gridH {
      background-color: #eee;
      min-height: 600px;
      margin-bottom: 50px;
  }

  #gridH .row {
      margin-top: 150px;
  }

  #gridH .row .full {
      margin-top: -150px;
  }

  .full:hover {
      transform: scale(1);
      box-shadow: none;
  }

  .opacity {
      opacity: .8;
  }

  .captionBox {
      background-color: rgba(0, 0, 0, 0.8);
      bottom: 50px;
      color: white;
      left: 50px;
      padding: 25px;
      position: absolute;
      opacity: 0;
  }

  .captionBox p {
      font-size: 16px;
      font-weight: 300;
  }

  .captionBox h3 {
      margin-top: 0;
      text-transform: uppercase;
  }

  .transition {
      transition: 1s;
  }

  .bottomRight {
      left: auto;
      right: 80px;
  }

  .topRight {
      left: auto;
      right: 50px;
      bottom: auto;
      top: 50px;
  }

  .topLeft {
      bottom: auto;
      top: 50px;
  }

  .toggleIcon {
      background-color: rgba(0, 0, 0, 0.7);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 26px 26px;
      border-radius: 3px;
      bottom: 15px;
      height: 36px;
      left: -100px;
      opacity: 0;
      padding: 10px;
      position: absolute;
      width: 36px;
  }

  .box.full .toggleIcon {
      background-color: rgba(0, 0, 0, 0.57);
      background-position: center center;
      background-repeat: no-repeat;
      border-radius: 3px;
      top: 26px;
      height: 36px;
      left: 50px;
      opacity: 0;
      padding: 10px;
      position: absolute;
      width: 36px;
  }

  .box:hover .toggleIcon {
      opacity: 1;
      left: 15px;
      transition: all 0.25s ease 0s;
      /* background-image: url('http://www.ucdenver.edu/SiteCollectionImages/GridGallery/openIcon.png'); */
  }

  .box.full .toggleIcon {
      opacity: 1;
      left: 50px;
      top: 26px;
      border-radius: 0;
      /* background-image: url('http://www.ucdenver.edu/SiteCollectionImages/GridGallery/closeIcon.png'); */
      background-size: 55%;
  }

  .box.full .toggleIcon:hover {
      background-color: black;
      transition: all 0s ease 0;
  }

  #gridGallery>.row {
      margin: 0;
  }

  @media (max-width: 1200px) {
      .full {
          width: 970px;
      }
  }

  @media (max-width: 992px) {
      .full {
          width: 750px;
      }
  }

  @media (max-width: 768px) {

      #boxThree,
      #boxFive,
      #boxSix {
          height: 300px;
      }

      .toggleIcon {
          display: none;
      }

      .full .captionBox {
          bottom: 0;
          left: 0;
          position: absolute;
          width: 100%;
          padding: 10px;
      }

      .full .captionBox p {
          display: none;
      }

      .full .captionBox h3 {
          margin-bottom: 0;
      }

      .box.full {
          height: inherit;
          width: 100%;
      }

      /* .box {
          max-height: 150px !important;
      } */

      #gridGallery .row {
          background-color: transparent;
      }

      #gridH .row {
          margin-top: 0px;
      }

      .box.full .toggleIcon {
          height: 50px !important;
          left: 0 !important;
          top: 0 !important;
          width: 50px !important;
          display: block;
      }

      .mobileFunction {
          float: left;
          height: 150px;
          width: 100%;
      }

      /* SHAREPOINT ONLY */
  }