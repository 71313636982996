.dx-popup-wrapper > .dx-overlay-content{
    transform: initial !important;
}

.submit-btn{
    border: none;
    background: rgba(33, 33, 33, 0.7);
    font-size: 18px;
    color: white;
    border-radius: 3px;
    padding: 10px 15px;
    text-align: center;
    margin-top: 35px;
}