.snip1226 {
  font-family: 'Raleway', Arial, sans-serif;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;

  color: rgba(255, 255, 255, 0.5);
  letter-spacing: 1px;
  margin: 0;
  text-decoration: none;
}

.snip1226 * {
  box-sizing: border-box;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}

.snip1226 li {
  display: inline-block;
  list-style: outside none none;
  margin: 0 13px;
  overflow: hidden;
}

.snip1226 a {
  padding: 0.3em 0;
  color: rgba(255, 255, 255, 0.5);
  position: relative;
  display: inline-block;
  letter-spacing: 1px;
  margin: 0;
  text-decoration: none;
}

.snip1226 a:before,
.snip1226 a:after {
  position: absolute;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}

.snip1226 a:before {
  bottom: 100%;
  display: block;
  height: 3px;
  width: 100%;
  content: "";
  background-color: #e67e22;
}

.snip1226 a:after {
  padding: 0.3em 0;
  position: absolute;
  bottom: 100%;
  left: 0;
  content: attr(data-hover);
  color: white;
  white-space: nowrap;
}

.snip1226 li:hover a,
.snip1226 .current a {
  transform: translateY(100%);
}

/*

*/
nav.page-header {
  background-color: rgba(33, 33, 33, 0.7) !important;
}

nav i.fa-home {
  padding: 0.3em 0;
  color: rgba(255, 255, 255, 0.5);
  position: relative;
  display: inline-block;
  letter-spacing: 1px;
  margin: 0;
  text-decoration: none;
}

#mobil-menu-nav-item {
  display: none;
}

#mobil-menu-nav-item nav.navbar {
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 8px;
}

nav#sidebar-menu,
nav#sidebar-menu2 {
  position: absolute;
  min-height: 2500px;
}

@media (max-width: 912px) {
  #main-content {
    /* margin-top: 84px; */
    margin-top: 182px;
  }
}

@media (max-width: 1020px) {
  #mobil-menu-nav-item {
    display: inline-block;
    float: right;
  }

  .navbar-expand-lg .navbar-toggler {
    display: block;
  }

  nav#sidebar-menu,
  nav#sidebar-menu2 {
    margin-top: 108px;
  }
}

@media (max-width: 942px) {

  nav#sidebar-menu,
  nav#sidebar-menu2 {
    margin-top: 98px;
  }
}

@media (max-width: 736px) {

  nav#sidebar-menu,
  nav#sidebar-menu2 {
    margin-top: 131px;
  }
}