@import url(https://fonts.googleapis.com/css?family=Raleway:400,500,800);
body{
    font-family: 'Raleway', Arial, sans-serif;
}

#rolunk,#kapcsolat,#referenciak,#partnereink {
    margin: 0 0 0px 0;
    margin-top:58px;
    width: calc(100% - 265px);
    margin-left: 253px;
  }
  

@media (max-width: 992px) {
    #rolunk,#kapcsolat,#referenciak,#partnereink {
        width: calc(100% - 12px);
        margin-left: initial;
    }
}