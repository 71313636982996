#sidebar-menu .snip1226.belepes-menu li:hover a,#sidebar-menu2 .snip1226.belepes-menu li:hover a{
  transform: none;
}

nav#sidebar-menu,nav#sidebar-menu2{
    background-color: rgba(33,33,33,0.7) !important;
    text-align: left;
    margin-top: 50px;
}
nav#sidebar-menu li,nav#sidebar-menu2 li{
    margin-bottom: 25px;
    text-align: left;
    margin-left: 0;
    width: calc(100% - 13px);
}
nav#sidebar-menu .position-sticky,nav#sidebar-menu2 .position-sticky{padding-top: 45px;}
nav#sidebar-menu ul,nav#sidebar-menu2 ul{text-align: left;}
nav i.fa-home{
  padding: 0.3em 0;
  color: rgba(255, 255, 255, 0.5);
  position: relative;
  display: inline-block;
  letter-spacing: 1px;
  margin: 0;
  text-decoration: none;
}


@media (max-width: 1194px) {
  nav#sidebar-menu,nav#sidebar-menu2{
    margin-top: 83px;
  }
}